import { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import CooperativesTable from 'cooperatives/components/table/CooperativesTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  COOPERATIVES_QUERY,
  CooperativesList,
} from 'cooperatives/graphql/cooperativesQuery';
import {
  COOPERATIVE_DELETE_MUTATION,
  CooperativeDeleteMutationVariables,
} from 'cooperatives/graphql/cooperativesDeleteMutation';
import { Cooperative } from 'cooperatives/models/cooperative';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { useHistory } from 'react-router-dom';
import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { CooperativeTypename } from 'cooperatives/graphql/coopertativeQuery';

type ListState = {
  cooperatives: Cooperative[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_COOPERATIVES_CREATE_ROUTE = '/dashboard/cooperatives/create';

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Cooperativas',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Cooperativa deletada com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar a Cooperativa',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function CooperativesPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [search, setSearch] = useState('');
  const [currentCooperativeId, setCurrentCooperativeId] = useState<number>(0);
  const [listState, setListState] = useState<ListState>({
    cooperatives: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [cooperativesList, { data, loading, error, refetch }] =
    useLazyQuery<CooperativesList>(COOPERATIVES_QUERY, INITIAL_QUERY_STATE_CONFIG);

  const [cooperativeDeleteMutation, { loading: cooperativeDeleteLoading }] =
    useMutation<CooperativeDeleteMutationVariables>(COOPERATIVE_DELETE_MUTATION, {
      onError() {
        addToast(DETETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    });

  useEffect(() => {
    cooperativesList();
  }, [cooperativesList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || cooperativeDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        cooperatives: data.cooperatives.entries,
        hasNextPage: !!data.cooperatives.afterCursor,
        hasPreviousPage: !!data.cooperatives.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.cooperatives.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.cooperatives.beforeCursor,
      });
  };

  const onClickEditButton = (id: number) =>
    push(`/dashboard/cooperatives/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentCooperativeId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    cooperativeDeleteMutation({
      variables: { id: currentCooperativeId },
      update(cache) {
        updateCacheById(cache, currentCooperativeId, CooperativeTypename);
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () => push(DASHBOARD_COOPERATIVES_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="Cooperativa"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={
        <DashboardMainHeaderTitle title="Gestão de Cooperativas" />
      }
    >
      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <CooperativesTable
              cooperatives={listState.cooperatives}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação da cooperativa!"
          text="Tem certeza que deseja desativar essa cooperativa? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
