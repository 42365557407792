import { gql } from '@apollo/client';
import {
  IcmsConsumerUnitConsumptionClassType,
  IcmsRangesFeeType,
  IcmsTax,
} from 'icmsTaxes/models/icmsTaxes';

export interface IcmsTaxUpdateMutationInput {
  id: number;
  icmsCountryState: string;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidityStartDate: string;
  icmsValidityEndDate: string;
  icmsRangesFee: IcmsRangesFeeType[];
}

export interface IcmsTaxUpdateMutationVariables {
  icmsTaxUpdateInput: IcmsTaxUpdateMutationInput;
}

export interface IcmsTaxUpdated {
  icmsTaxUpdate: IcmsTax;
}

export const ICMS_TAX_UPDATE_MUTATION = gql`
  mutation icmsTaxUpdate($icmsTaxUpdateInput: IcmsTaxUpdateInput!) {
    icmsTaxUpdate(input: $icmsTaxUpdateInput) {
      id
      icmsValidityStartDate
      icmsValidityEndDate
      icmsRangesFee {
        consumptionRangePercentage
        consumptionKwhStartRange
        consumptionKwhEndRange
      }
    }
  }
`;
