import { gql } from '@apollo/client';

export type PowerDistributionUnitTariffGroupDeleteMutationVariables = {
  id: number;
};

export const powerDistributionUnitTariffGroupTypename =
  'PowerDistributionUnitTariffGroup';

export const POWER_DISTRIBUTION_UNIT_TARIFF_GROUP_DELETE_MUTATION = gql`
  mutation powerDistributionUnitTariffGroupDelete($id: ID!) {
    powerDistributionUnitTariffGroupDelete(id: $id) {
      message
    }
  }
`;
