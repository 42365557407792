import { camelCase } from 'lodash';
import { ApolloError } from '@apollo/client';
import { ErrorOption } from 'react-hook-form';
import { TFunction } from 'i18next';

export const setFormError = (
  error: ApolloError,
  setError: (
    field: string,
    error: ErrorOption,
    options: { shouldFocus: true }
  ) => void,
  t: TFunction
) => {
  let errors: { [key: string]: string } = {};

  return error.message.split('\n').forEach((errorMessage: string) => {
    const errorMessageSlices = errorMessage.split(' ');
    const errorType = errorMessageSlices[0];
    const errorMesage = errors[errorType] ?? '';

    errors[errorType] = errorMesage
      .concat(' ')
      .concat(t(errorMessageSlices.splice(1).join(' ')));

    setError(
      camelCase(errorType),
      {
        type: 'manual',
        message: errors[errorType],
      },
      { shouldFocus: true }
    );
  });
};

export const FORM_ERRORS_MAP: Record<string, string> = {
  required: 'Campo obrigatório!',
};
