import {
  FieldError,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
  Control,
  Controller,
} from 'react-hook-form';
import TextField from 'ui/form/TextField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import SelectField from 'ui/form/SelectField';
import DatePickerField from 'ui/form/DatePickerField';
import { IcmsConsumerUnitConsumptionClassType } from 'icmsTaxes/models/icmsTaxes';
import { useEffect } from 'react';

export type IcmsRangesFeeType = {
  consumptionRangePercentage: string;
  consumptionKwhStartRange: string;
  consumptionKwhEndRange: string;
}[];

export type SubmitFormFields = {
  icmsCountryState: string;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidityStartDate: string;
  icmsValidityEndDate: string;
  icmsRangesFee: IcmsRangesFeeType;
};

export type FormFields = {
  icmsCountryState: string;
  icmsConsumerUnitConsumptionClass: IcmsConsumerUnitConsumptionClassType;
  icmsValidityStartDate: Date;
  icmsValidityEndDate: Date;
  icmsRangesFee: IcmsRangesFeeType;
};

export type FormErrors = {
  icmsCountryState?: FieldError | undefined;
  icmsConsumerUnitConsumptionClass?: FieldError | undefined;
  icmsValidityStartDate?: FieldError | undefined;
  icmsValidityEndDate?: FieldError | undefined;
  icmsRangesFee?: FieldErrors | undefined;
};

const icmsConsumerUnitConsumptionClassOptions = [
  {
    key: '',
    value: 'Selecione uma classe de consumo',
  },
  {
    key: 'COMMERCIAL',
    value: 'COMMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENTIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

type Field =
  | 'icmsCountryState'
  | 'icmsConsumerUnitConsumptionClass'
  | 'icmsValidityStartDate'
  | 'icmsValidityEndDate'
  | 'icmsRangesFee';

export const FORM_FIELDS: Field[] = [
  'icmsCountryState',
  'icmsConsumerUnitConsumptionClass',
  'icmsValidityStartDate',
  'icmsValidityEndDate',
  'icmsRangesFee',
];

export default function IcmsTaxFormFields({
  errors,
  register,
  control,
  disableCountryStateSelect,
  disableConsumerUnitConsumptionClassSelect,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  disableCountryStateSelect?: boolean;
  disableConsumerUnitConsumptionClassSelect?: boolean;
}) {
  const { fields, append } = useFieldArray({
    name: 'icmsRangesFee',
    control,
  });

  useEffect(() => {
    const icmsRangesFee = Array(5)
      .fill(0)
      .map((_, i) => ({
        consumptionRangePercentage: '',
        consumptionKwhStartRange: '',
        consumptionKwhEndRange: '',
      }));

    append(icmsRangesFee);
  }, [append]);

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <CountryStateSelectField
          error={errors.icmsCountryState?.message}
          label="Estado"
          id="icmsCountryState"
          {...register('icmsCountryState', {
            required: true,
            disabled: disableCountryStateSelect,
          })}
          disabled={disableCountryStateSelect}
        />

        <SelectField
          error={errors.icmsConsumerUnitConsumptionClass?.message}
          label="Tipo de ICMS"
          id="icmsConsumerUnitConsumptionClass"
          {...register('icmsConsumerUnitConsumptionClass', {
            required: true,
            disabled: disableConsumerUnitConsumptionClassSelect,
          })}
          options={icmsConsumerUnitConsumptionClassOptions}
          disabled={disableConsumerUnitConsumptionClassSelect}
        />

        <div className="flex row">
          <Controller
            render={({ field: { onChange, value } }) => (
              <DatePickerField
                error={errors.icmsValidityStartDate?.message}
                id="icmsValidityStartDate"
                {...register('icmsValidityStartDate', {
                  required: true,
                })}
                labelDate="Inicio da validade"
                onChange={onChange}
                value={value}
              />
            )}
            name="icmsValidityStartDate"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field: { onChange, value } }) => (
              <DatePickerField
                error={errors.icmsValidityEndDate?.message}
                id="icmsValidityEndDate"
                {...register('icmsValidityEndDate', {
                  required: true,
                })}
                labelDate="Fim da validade"
                onChange={onChange}
                value={value}
              />
            )}
            name="icmsValidityEndDate"
            control={control}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <p className="text-gray-dark400 font-bold text-sm pt-8 pb-2">
                  {`${index + 1}º Faixa`}
                </p>
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    error={errors?.icmsRangesFee?.message}
                    label="Kwh Inicial"
                    id={`icmsRangesFee.${index}.consumptionKwhStartRange`}
                    {...register(`icmsRangesFee.${index}.consumptionKwhStartRange`, {
                      required: true,
                    })}
                    placeholder="Kwh"
                    className="placeholder-right"
                    data-testid={`consumption-kwh-start-range-${index}`}
                  />

                  <TextField
                    error={errors?.icmsRangesFee?.message}
                    label="Kwh Final"
                    id={`icmsRangesFee.${index}.consumptionKwhEndRange`}
                    {...register(`icmsRangesFee.${index}.consumptionKwhEndRange`, {
                      required: true,
                    })}
                    placeholder="Kwh"
                    className="placeholder-right"
                    data-testid={`consumption-kwh-end-range-${index}`}
                  />

                  <TextField
                    error={errors?.icmsRangesFee?.message}
                    label="Porcentagem"
                    id={`icmsRangesFee.${index}.consumptionRangePercentage`}
                    {...register(
                      `icmsRangesFee.${index}.consumptionRangePercentage`,
                      {
                        required: true,
                      }
                    )}
                    placeholder="%"
                    className="placeholder-right"
                    data-testid={`consumption-kwh-range-percentage-${index}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
