import { gql } from '@apollo/client';
import { Cooperative } from 'cooperatives/models/cooperative';

export interface CooperativeUpdateMutationInput {
  id: number;
  cooperativeSubscriptionCostCents: number;
  cooperativeLegalName: string;
  cooperativeDocumentId: string;
  cooperativeAddressPostalCode: number;
  cooperativeAddressState: string;
  cooperativeAddressCity: string;
  cooperativeAddressStreet: string;
  cooperativeAddressComplement: string | null;
}

export interface CooperativeUpdateMutationVariables {
  cooperativeUpdateInput: CooperativeUpdateMutationInput;
}

export interface CooperativeUpdated {
  cooperativeUpdate: Cooperative;
}

export const COOPERATIVE_UPDATE_MUTATION = gql`
  mutation cooperativeUpdate($cooperativeUpdateInput: CooperativeUpdateInput!) {
    cooperativeUpdate(input: $cooperativeUpdateInput) {
      id
      cooperativeAddressCity
      cooperativeAddressPostalCode
      cooperativeAddressState
      cooperativeAddressStreet
      cooperativeAddressComplement
      cooperativeDocumentId
      cooperativeLegalName
      cooperativeSubscriptionCostCents
    }
  }
`;
