import { gql } from '@apollo/client';
import { PowerDistributionUnitTariffGroup } from 'powerDistributionUnits/models/powerDistributionUnitTariffGroup';

interface powerDistributionUnitTariffGroupConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: PowerDistributionUnitTariffGroup[];
}

export interface powerDistributionUnitTariffGroupList {
  powerDistributionUnitTariffGroups: powerDistributionUnitTariffGroupConnection;
}

export const powerDistributionUnitTariffGroupTypename =
  'powerDistributionUnitTariffGroupConnection';

export const POWER_DISTRIBUTION_UNIT_TARIFF_GROUPS_LIST_QUERY = gql`
  query powerDistributionUnitTariffGroups(
    $after: String
    $before: String
    $pageSize: Int
  ) {
    powerDistributionUnitTariffGroups(
      after: $after
      before: $before
      pageSize: $pageSize
    ) {
      entries {
        id
        tariffGroupConsumptionCosts {
          energyUsageTariffOnPeak
          energyUsageTariffOffPeak
          energyDistributionServiceTariffOnPeak
          energyDistributionServiceTariffOffPeak
          energyTariff
          energyDistributionServiceTariff
        }
        tariffGroupDemandCosts {
          all
          onPeak
          offPeak
        }
        tariffGroupConsumptionGroup
        tariffGroupModality
        tariffGroupValidityEndDate
        tariffGroupValidityStartDate
        powerDistributionUnitId
        powerDistributionUnit {
          powerDistributionUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;
