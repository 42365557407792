import React from 'react';
import RadioField from 'ui/form/RadioField';

export const UiDashboard = () => {
  let [value, setValue] = React.useState('startup');

  return (
    <div className="w-screen h-screen bg-gray-dark800 p-4">
      <RadioField
        value={value}
        onChange={setValue}
        options={[
          {
            label: 'First Radio Input Option',
            value: '1',
          },
          {
            label: 'Second Radio Input Option',
            value: '2',
          },
          {
            label: 'Third Radio Input Option',
            value: '3',
            disabled: true,
          },
        ]}
        label="Options"
      />
    </div>
  );
};

export default UiDashboard;
