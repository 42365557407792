import { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateCacheById } from 'graphql/apollo/cache';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import IcmsTaxesTable from 'icmsTaxes/components/table/IcmsTaxesTable';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import { ICMS_TAXES_QUERY } from 'icmsTaxes/graphql/icmsTaxesQuery';
import {
  ICMS_TAX_DELETE_MUTATION,
  IcmsTaxDeleteMutationVariables,
  icmsTaxTypename,
} from 'icmsTaxes/graphql/icmsTaxDeleteMutation';
import { IcmsTax } from 'icmsTaxes/models/icmsTaxes';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import { useHistory } from 'react-router';

type State = {
  icmsTaxes: IcmsTax[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de ICMS',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'ICMS deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DELETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar ICMS',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const DASHBOARD_ICMS_TAXES_CREATE_ROUTE =
  '/dashboard/taxes-fees-management/icms-taxes/create';

export default function PowerDistributionUnitTariffGroupsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [currentIcmsTaxId, setCurrentIcmsTaxId] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [state, setState] = useState<State>({
    icmsTaxes: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [icmsTaxDeleteMutation, { loading: icmsTaxDeleteLoading }] =
    useMutation<IcmsTaxDeleteMutationVariables>(ICMS_TAX_DELETE_MUTATION, {
      onError() {
        addToast(DELETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    });

  const [icmsTaxesList, { data, loading, error, refetch }] = useLazyQuery(
    ICMS_TAXES_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    icmsTaxesList();
  }, [icmsTaxesList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || icmsTaxDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setState({
        icmsTaxes: data.icmsTaxes.entries,
        hasNextPage: !!data.icmsTaxes.afterCursor,
        hasPreviousPage: !!data.icmsTaxes.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () =>
    refetch &&
    refetch({
      after: data.icmsTaxes.afterCursor,
      before: null,
    });

  const handleClickBefore = () =>
    refetch &&
    refetch({
      after: null,
      before: data.icmsTaxes.beforeCursor,
    });

  const onClickEditButton = (id: number) =>
    push(`/dashboard/taxes-fees-management/icms-taxes/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentIcmsTaxId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    icmsTaxDeleteMutation({
      variables: { id: currentIcmsTaxId },
      update(cache) {
        updateCacheById(cache, currentIcmsTaxId, icmsTaxTypename);
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () => push(DASHBOARD_ICMS_TAXES_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="ICMS"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={<DashboardMainHeaderTitle title="ICMS" />}
    >
      <div className="rounded-lg">
        <IcmsTaxesTable
          icmsTaxes={state.icmsTaxes}
          onClickEditButton={onClickEditButton}
          onClickRemoveButton={onClickRemoveButton}
        />
        <Pagination
          onNextClick={handleClickNext}
          onPreviousClick={handleClickBefore}
          disableNext={!state.hasNextPage}
          disableBefore={!state.hasPreviousPage}
        />
        <ConfirmOverlay
          title="Remoção do ICMS"
          text="Tem certeza que deseja deletar ICMS? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
