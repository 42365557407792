import { PowerDistributionUnitTariffGroupProps } from 'powerDistributionUnits/models/powerDistributionUnitTariffGroup';
import PowerDistributionUnitTariffGroupTableItem from './PowerDistributionUnitTariffGroupTableItem';
import Table from 'ui/components/table/Table';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';
import TableBody from 'ui/components/table/TableBody';

type Props = {
  powerDistributionUnitTariffGroups: PowerDistributionUnitTariffGroupProps[];
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function TariffGroupsTable({
  powerDistributionUnitTariffGroups,
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>Concessionária</TableHeadColumn>
        <TableHeadColumn>Validade</TableHeadColumn>
        <TableHeadColumn>Grupo</TableHeadColumn>
        <TableHeadColumn>Modalidade</TableHeadColumn>
        <TableHeadColumn>Consumo TE</TableHeadColumn>
        <TableHeadColumn>Consumo TUSD</TableHeadColumn>
        <TableHeadColumn>Demanda</TableHeadColumn>
        <TableHeadColumn className="text-right">Ações</TableHeadColumn>
      </TableHead>
      <TableBody>
        {powerDistributionUnitTariffGroups.map(
          (
            powerDistributionUnitTariffGroup: PowerDistributionUnitTariffGroupProps
          ) => (
            <PowerDistributionUnitTariffGroupTableItem
              key={powerDistributionUnitTariffGroup.id}
              powerDistributionUnitTariffGroup={powerDistributionUnitTariffGroup}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
