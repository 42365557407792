import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import useToastContext from 'ui/hooks/useToast';
import { updateCacheById } from 'graphql/apollo/cache';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import {
  Dashboard,
  DashboardHeaderSearch,
  DashboardMainHeaderTitle,
} from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import {
  PIS_COFINS_TAXES_QUERY,
  PisCofinsTaxesList,
  pisCofinsTaxesTypename,
} from 'pisCofinsTaxes/graphql/pisCofinsTaxesQuery';
import {
  PIS_COFINS_TAX_DELETE_MUTATION,
  PisCofinsTaxDeleteMutationVariables,
} from 'pisCofinsTaxes/graphql/pisCofinsTaxesDeleteMutation';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';
import PisCofinsTaxesTable from 'pisCofinsTaxes/components/table/PicCofinsTaxesTable';

type ListState = {
  pisCofinsTaxes: PisCofinsTax[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const DASHBOARD_PIS_COFINS_TAXES_CREATE_ROUTE =
  '/dashboard/power-distribution-units/pis-cofins-taxes/create';

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de PIS/Cofins',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'PIS/Cofins deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar o PIS/Cofins',
  title: 'Algo deu errado!',
  variant: 'danger',
};

export default function PisCofinsTaxes() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [search, setSearch] = useState('');
  const [currentPisCofinsTaxId, setCurrentPisCofinsTaxId] = useState<number>(0);
  const [listState, setListState] = useState<ListState>({
    pisCofinsTaxes: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [pisCofinsTaxesList, { data, loading, error, refetch }] =
    useLazyQuery<PisCofinsTaxesList>(
      PIS_COFINS_TAXES_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [pisCofinsTaxDeleteMutation, { loading: pisCofinsTaxDeleteLoading }] =
    useMutation<PisCofinsTaxDeleteMutationVariables>(
      PIS_COFINS_TAX_DELETE_MUTATION,
      {
        onError() {
          addToast(DETETE_ERROR_TOAST);
        },
        onCompleted() {
          addToast(DELETE_SUCCESS_TOAST);
        },
      }
    );

  useEffect(() => {
    pisCofinsTaxesList();
  }, [pisCofinsTaxesList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || pisCofinsTaxDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    if (data) {
      setListState({
        pisCofinsTaxes: data.powerDistributionUnitPisCofinsTaxes.entries,
        hasNextPage: !!data.powerDistributionUnitPisCofinsTaxes.afterCursor,
        hasPreviousPage: !!data.powerDistributionUnitPisCofinsTaxes.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () =>
    refetch &&
    refetch({
      after: data?.powerDistributionUnitPisCofinsTaxes.afterCursor,
      before: null,
    });

  const handleClickBefore = () =>
    refetch &&
    refetch({
      after: null,
      before: data?.powerDistributionUnitPisCofinsTaxes.beforeCursor,
    });

  const onClickEditButton = (id: number) =>
    push(`/dashboard/power-distribution-units/pis-cofins-taxes/${id}/edit`);

  const onClickRemoveButton = (id: number) => {
    setCurrentPisCofinsTaxId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    pisCofinsTaxDeleteMutation({
      variables: { id: currentPisCofinsTaxId },
      update(cache) {
        updateCacheById(cache, currentPisCofinsTaxId, pisCofinsTaxesTypename);
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    console.log({ search });
  };

  const onClickAddButton = () => push(DASHBOARD_PIS_COFINS_TAXES_CREATE_ROUTE);

  return (
    <Dashboard
      dashboardHeader={
        <DashboardHeaderSearch
          addButtonLabel="PIS/COFINS"
          onClickAddButton={onClickAddButton}
          onSearchChange={handleSearch}
        />
      }
      dashboardMainHeaderTitle={<DashboardMainHeaderTitle title="PIS/COFINS" />}
    >
      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <PisCofinsTaxesTable
              pisCofinsTaxes={listState.pisCofinsTaxes}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Desativação de PIS/COFINS"
          text="Tem certeza que deseja desativar essa taxa de PIS/Cofins? Todos os dados serão permanentemente removidos do nosso sistema. Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
