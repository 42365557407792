import { FormContainer } from 'ui';
import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';

type Field =
  | 'cooperativeHeadquarterId'
  | 'cooperativeSubscriptionCostCents'
  | 'cooperativeLegalName'
  | 'cooperativeDocumentId'
  | 'cooperativeAddressPostalCode'
  | 'cooperativeAddressState'
  | 'cooperativeAddressCity'
  | 'cooperativeAddressStreet'
  | 'cooperativeAddressComplement';

export const FORM_FIELDS: Field[] = [
  'cooperativeHeadquarterId',
  'cooperativeSubscriptionCostCents',
  'cooperativeLegalName',
  'cooperativeDocumentId',
  'cooperativeAddressPostalCode',
  'cooperativeAddressState',
  'cooperativeAddressCity',
  'cooperativeAddressStreet',
  'cooperativeAddressComplement',
];
export type FormFields = {
  cooperativeSubscriptionCostCents: number;
  cooperativeLegalName: string;
  cooperativeDocumentId: string;
  cooperativeAddressPostalCode: number;
  cooperativeAddressState: string;
  cooperativeAddressCity: string;
  cooperativeAddressStreet: string;
  cooperativeAddressComplement: string | null;
  cooperativeHeadquarterId: number | null;
};

export type FormErrors = {
  cooperativeSubscriptionCostCents?: FieldError | undefined;
  cooperativeLegalName?: FieldError | undefined;
  cooperativeDocumentId?: FieldError | undefined;
  cooperativeAddressPostalCode?: FieldError | undefined;
  cooperativeAddressState?: FieldError | undefined;
  cooperativeAddressCity?: FieldError | undefined;
  cooperativeAddressStreet?: FieldError | undefined;
  cooperativeAddressComplement?: FieldError | undefined;
  cooperativeHeadquarterId?: FieldError | undefined;
};

const EMPTY_HEADQUARTER_OPTION = { key: '', value: 'Selecione uma Matriz' };

export default function CooperativeFormFields({
  headquarters,
  headquarterValue,
  disableHeadquarterSelect,
  errors,
  register,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  headquarters?: CooperativeSelect[];
  headquarterValue?: string;
  disableHeadquarterSelect?: boolean;
}) {
  const [headquarterOptions, setHeadquarterOptions] = useState<Option[]>([]);
  useEffect(
    () =>
      setHeadquarterOptions([
        EMPTY_HEADQUARTER_OPTION,
        ...(headquarters?.map((cooperative) => ({
          key: `${cooperative.id}`,
          value: cooperative.cooperativeLegalName,
        })) || []),
      ]),
    [headquarters]
  );

  return (
    <FormContainer>
      <SelectField
        error={errors.cooperativeHeadquarterId?.message}
        label="Nome da Matriz"
        id="cooperativeHeadquarterId"
        {...register('cooperativeHeadquarterId', {
          required: false,
        })}
        options={headquarterOptions}
        value={headquarterValue}
        disabled={disableHeadquarterSelect}
      />
      <TextField
        error={errors.cooperativeLegalName?.message}
        label="Razão Social"
        id="cooperativeLegalName"
        {...register('cooperativeLegalName', { required: true })}
        placeholder="Ex.: Nex Energy Gestão de Energia"
      />
      <NumberOnlyField
        error={errors.cooperativeSubscriptionCostCents?.message}
        label="Valor da subscrição (em centavos)"
        id="cooperativeSubscriptionCostCents"
        {...register('cooperativeSubscriptionCostCents', {
          required: true,
          valueAsNumber: true,
          validate: (cost) => cost > 0,
        })}
        placeholder="2000"
      />
      <TextField
        error={errors.cooperativeDocumentId?.message}
        label="Documento"
        id="cooperativeDocumentId"
        {...register('cooperativeDocumentId', { required: true })}
        placeholder="Ex.: 28.054.303/0001-06"
      />
      <NumberOnlyField
        error={errors.cooperativeAddressPostalCode?.message}
        label="CEP"
        id="cooperativeAddressPostalCode"
        {...register('cooperativeAddressPostalCode', {
          required: true,
          valueAsNumber: true,
        })}
        placeholder="81000000"
      />
      <CountryStateSelectField
        error={errors.cooperativeAddressState?.message}
        label="Estado"
        id="cooperativeAddressState"
        {...register('cooperativeAddressState', {
          required: true,
        })}
      />
      <TextField
        error={errors.cooperativeAddressCity?.message}
        label="Cidade"
        id="cooperativeAddressCity"
        {...register('cooperativeAddressCity', { required: true })}
        placeholder="Ex.: Curitiba"
      />
      <TextField
        error={errors.cooperativeAddressStreet?.message}
        label="Endereço"
        id="cooperativeAddressStreet"
        {...register('cooperativeAddressStreet', { required: true })}
        placeholder="Ex.: Comendador Franco 777"
      />
      <TextField
        error={errors.cooperativeAddressComplement?.message}
        label="Complemento"
        id="cooperativeAddressComplement"
        {...register('cooperativeAddressComplement', { required: false })}
        placeholder="Ex.: Sala 1"
      />
    </FormContainer>
  );
}
