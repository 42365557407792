import React from 'react';
import classNames from 'classnames';
import RadioInput, { Props as BaseProps } from 'ui/components/form/RadioInput';

type Props<T> = BaseProps<T> & {
  label?: string;
  wrapperClassName?: string;
};

const ForwardRefRadioInput = <T extends unknown>(
  { className, wrapperClassName, label, disabled, id, ...props }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <fieldset className={wrapperClassName}>
      <label
        htmlFor={id}
        className={classNames('block text-sm font-medium text-gray-dark400 mb-3', {
          'text-gray-dark600': disabled,
        })}
      >
        {label}
      </label>
      <RadioInput id={id} {...props} disabled={disabled} ref={ref} />
    </fieldset>
  );
};

export const RadioField = React.forwardRef(ForwardRefRadioInput) as <T>(
  props: Props<T>
) => ReturnType<typeof ForwardRefRadioInput>;

export default RadioField;
