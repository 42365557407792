import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import IcmsTaxFormFields, {
  SubmitFormFields,
  FormFields,
} from 'icmsTaxes/components/form/IcmsTaxFields';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { setFormError } from 'utils/form';
import {
  IcmsTaxCreated,
  IcmsTaxMutationVariables,
  ICMS_TAX_CREATE_MUTATION,
} from 'icmsTaxes/graphql/icmsTaxCreateMutation';
import {
  icmsTaxTypename,
  IcmsTaxList,
  ICMS_TAXES_QUERY,
} from 'icmsTaxes/graphql/icmsTaxesQuery';
import useToastContext from 'ui/hooks/useToast';
import { useHistory } from 'react-router';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { useEffect } from 'react';
import { has } from 'lodash';

const DASHBOARD_ICMS_TAXES_ROUTE = '/dashboard/taxes-fees-management/icms-taxes';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Gestão de impostos',
    route: DASHBOARD_ICMS_TAXES_ROUTE,
    current: false,
  },
  { name: 'ICMS', route: DASHBOARD_ICMS_TAXES_ROUTE, current: false },
  {
    name: 'Novo ICMS',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo ICMS';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar o ICMS',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Successfully saved!',
  variant: 'primary',
  text: 'Anyone with a link can now view this file.',
};

const getPartsFromDate = (
  date: string,
  options: Intl.DateTimeFormatOptions | undefined
) => new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));

export const formatDate = (date: string) =>
  `${getPartsFromDate(date, { year: 'numeric' })}-${getPartsFromDate(date, {
    month: '2-digit',
  })}-${getPartsFromDate(date, { day: '2-digit' })}`;

export default function CreateIcmsTaxesPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [icmsTaxCreateMutation, { loading: loadingCreate }] = useMutation<
    IcmsTaxCreated,
    IcmsTaxMutationVariables
  >(ICMS_TAX_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_ICMS_TAXES_ROUTE);
    },
  });

  const onSubmit: SubmitHandler<SubmitFormFields> = (icmsTaxCreateInput) => {
    icmsTaxCreateInput.icmsValidityStartDate = formatDate(
      icmsTaxCreateInput.icmsValidityStartDate
    );

    icmsTaxCreateInput.icmsValidityEndDate = formatDate(
      icmsTaxCreateInput.icmsValidityEndDate
    );

    icmsTaxCreateMutation({
      variables: {
        icmsTaxCreateInput,
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<IcmsTaxList>({
          query: ICMS_TAXES_QUERY,
        });
        cache.writeQuery({
          query: ICMS_TAXES_QUERY,
          data: {
            icmsTaxes: {
              __typename: icmsTaxTypename,
              afterCursor: existingData?.icmsTaxes.afterCursor || null,
              beforeCursor: existingData?.icmsTaxes.beforeCursor || null,
              entries: [
                data?.icmsTaxCreate,
                ...(existingData?.icmsTaxes.entries || []),
              ],
            },
          },
        });
      },
    });
  };
  const isLoading = loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <IcmsTaxFormFields errors={errors} register={register} control={control} />
      </Dashboard>
    </form>
  );
}
