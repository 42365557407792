import React, { ChangeEvent } from 'react';
import { CalendarProps } from 'react-calendar';
import classnames from 'classnames';
import { Calendar } from 'ui';
import TextField from 'ui/form/TextField';
import { useOnClickOutside } from 'ui/hooks/useOnClickOutside';
export interface Props
  extends Omit<
    CalendarProps,
    'inputRef' | 'className' | 'selectRange' | 'returnValue' | 'value' | 'onChange'
  > {
  wrapperClassName?: string;
  rangeInputWrapperClassName?: string;
  calendarWrapperClassName?: string;
  calendarClassName?: string;
  labelDate?: string;
  value?: Date;
  onChange?: (value: Date, event: ChangeEvent<HTMLInputElement>) => void;
  locale?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  error?: string;
  id: string;
  positionTop?: number;
}

export const DatePickerField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      wrapperClassName,
      rangeInputWrapperClassName,
      calendarWrapperClassName,
      calendarClassName,
      labelDate = 'Start Date',
      disabled,
      error,
      id,
      value,
      onChange,
      locale = 'pt-BR',
      positionTop = 65,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState(value);
    const [open, setOpen] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const calendarRef = React.useRef<HTMLInputElement | null>(null);

    useOnClickOutside(
      calendarRef,
      React.useCallback(() => {
        setInternalValue(value);
        setOpen(false);
      }, [value])
    );

    React.useEffect(() => {
      setInternalValue(value);
      setOpen(false);
    }, [value]);

    const onFocusInput: React.InputHTMLAttributes<HTMLInputElement>['onFocus'] =
      () => setOpen(true);

    const onChangeCalendar = (value: Date, event: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;

      onChange(value, event);
      setInternalValue(value);
    };

    const inputStartDate = (() => {
      const date = internalValue;

      if (date) {
        return date.toLocaleDateString(locale);
      }

      return '';
    })();

    return (
      <div
        className={classnames(wrapperClassName, 'inline-flex relative')}
        ref={ref}
      >
        <div className={`${rangeInputWrapperClassName}`}>
          <TextField
            id={id}
            wrapperClassName="mr-4"
            label={labelDate}
            value={inputStartDate}
            disabled={disabled}
            readOnly
            error={error}
            onFocus={onFocusInput}
            ref={inputRef}
            name={id}
          />
        </div>
        <span className="contents">
          <div
            className={classnames(
              'w-350 absolute z-20 transform translate-y-4',
              calendarWrapperClassName
            )}
            style={{ top: positionTop }}
          >
            {open && (
              <Calendar
                {...props}
                value={internalValue}
                onChange={onChangeCalendar}
                locale={locale}
                showNeighboringMonth={false}
                allowPartialRange
                defaultView="month"
                minDetail="month"
                className={classnames(
                  'before:empty-content before:absolute before:z-10 before:-top-1 before:left-11 before:w-5 before:h-5 before:bg-gray-dark600 before:transform before:rotate-45',
                  calendarClassName
                )}
                ref={calendarRef}
              />
            )}
          </div>
        </span>
      </div>
    );
  }
);

export default DatePickerField;
